import browserHistory from '../../router/history';
import {
  setForm,
  setFormData,
  previousStep,
  nextStep,
  setFormError,
  validateForm,
} from '../dynamic-form/actions';
import { CHANGE_EMAIL_PAGE } from '../../shared/formPages';
import { showDefaultErrorNotification } from '../notification/actions';
import {
  makeSelectFormStep,
  makeSelectFormData,
} from '../dynamic-form/selectors';
import { VALIDATION_ERROR } from '../dynamic-form/constants';
import { CHANGE_EMAIL_HEADER } from '../../features/change-email/headers';
import { setEmailToVerify, setIsRegisterFlow } from '../verify-email/actions';
import {
  VERIFY_EMAIL_PATHNAME,
  EDIT_PROFILE_PATHNAME,
  PROFILE_PATHNAME,
} from '../../shared/pathnames';
import { getEditProfileChangeEmailForm } from '@shell-b2c/http-frontend/dist/src/components/core/forms';
import { makeSelectEmailToVerify } from '../verify-email/selectors';
import { updateUserEmail } from '@shell-b2c/http-frontend/dist/src/components/core/user';
import { makeSelectIsMobile } from '../browser/selectors';
import { refreshProfile, setProfileOption } from '../profile/actions';
import { redirectToCVP, saveCredentials, setSpinner } from '../app/actions';
import lokaliseTags from '../../shared/lokaliseTags';
import { login } from '@shell-b2c/http-frontend/dist/src/components/core/auth';
import { getCaptchaToken } from '../../shared/utils';
import { makeSelectQuery, makeSelectQueryScopes } from '../app/selectors';
import { makeSelectIsPhoneNumberFormActive } from '../auth/selectors';
import { makeSelectUserProfile } from '../profile/selectors';
import { showLastAttempPopup } from '../login/actions';

export function initializeChangeEmail(setHeader) {
  return async (dispatch, getState) => {
    const header = CHANGE_EMAIL_HEADER;
    header.menu.leftMenu.event = () => {
      browserHistory.back();
    };
    setHeader(header);

    const emailAddress = makeSelectEmailToVerify()(getState());
    const isMobile = makeSelectIsMobile()(getState());
    const formData = makeSelectFormData()(getState());

    dispatch(
      setFormData({
        ...formData,
        emailAddress: null,
      })
    );

    try {
      const form = await getEditProfileChangeEmailForm();
      dispatch(
        setForm({
          name: CHANGE_EMAIL_PAGE,
          data: {
            form,
            step: emailAddress && isMobile ? 1 : 0,
          },
        })
      );
      if (!emailAddress) {
        dispatch(setFormData({}));
      }
    } catch (error) {
      dispatch(showDefaultErrorNotification());
    }
  };
}

export function changeToPrevStep() {
  return (dispatch, getState) => {
    const step = makeSelectFormStep()(getState());
    if (step === 0) {
      browserHistory.back();
    } else {
      dispatch(previousStep());
    }
  };
}

export function handleChangeEmail() {
  return async (dispatch, getState) => {
    dispatch(setFormError({}));
    try {
      await dispatch(validateForm(CHANGE_EMAIL_PAGE));
      const { emailAddress: newEmail, currentPassword } = makeSelectFormData()(
        getState()
      );
      const data = {
        newEmail,
      };
      await updateUserEmail(data);
      dispatch(
        saveCredentials({
          username: newEmail,
          password: currentPassword,
          type: 'current-password',
        })
      );
      dispatch(refreshProfile());
      dispatch(setEmailToVerify(newEmail));
      dispatch(setIsRegisterFlow(false));
      browserHistory.push(`${VERIFY_EMAIL_PATHNAME}?logout=true`);
    } catch (error) {
      if (error.type !== VALIDATION_ERROR) {
        dispatch(
          showDefaultErrorNotification(
            null,
            lokaliseTags.SSO_ERROR_GENERIC_JANRAIN
          )
        );
      }
    }
  };
}
export function handleErrorLogin(error) {
  return async (dispatch) => {
    const isValidationFormError = error.type === VALIDATION_ERROR;
    const isAccountLocked = error.accountLocked();
    if (isValidationFormError || isAccountLocked) {
      return;
    }
    const errorsData = error.getData(0);
    const hasToShowLastAttempPopup =
      errorsData &&
      errorsData.maxLoginAttempts - errorsData.loginAttempts === 1;
    if (hasToShowLastAttempPopup) {
      dispatch(showLastAttempPopup());
    }
    dispatch(
      setFormError({
        currentPassword:
          lokaliseTags.SSO_FORMS_ERRORS_USERNAME_PASSWORD_INVALID,
      })
    );
  };
}

export function changeToNextStep(setHeaderMenuLeft, fromMobile) {
  return async (dispatch, getState) => {
    const step = makeSelectFormStep()(getState());
    const profile = makeSelectUserProfile()(getState());
    const { currentPassword } = makeSelectFormData()(getState());
    const isFirstStep = step === 0;
    const isPhoneNumberActive = makeSelectIsPhoneNumberFormActive()(getState());
    const { udid } = makeSelectQuery()(getState());
    const isPasswordFilled = currentPassword && currentPassword.length > 0;
    if (isFirstStep && isPasswordFilled) {
      try {
        dispatch(setSpinner(true));
        const recaptchaToken = await getCaptchaToken('login');
        const loginData = {
          password: currentPassword,
          udid,
          recaptchaToken,
          [isPhoneNumberActive ? 'mobile' : 'email']: isPhoneNumberActive
            ? profile?.phone?.mobile
            : profile.emailAddress,
        };
        const scopes = makeSelectQueryScopes()(getState()).join(' ');
        await login(loginData, { withMobile: isPhoneNumberActive, scopes });
        dispatch(setSpinner(false));
        dispatch(setFormError({}));
      } catch (error) {
        if (error.otpRequired()) {
          dispatch(setSpinner(false));
          dispatch(setFormError({}));
        } else if (error.scopesError()) {
          dispatch(
            showDefaultErrorNotification(
              () => dispatch(redirectToCVP()),
              lokaliseTags.SSO_TECHNICAL_ERROR_SCOPES
            )
          );
          return;
        } else {
          dispatch(handleErrorLogin(error));
          dispatch(setSpinner(false));
          if (fromMobile) {
            return false;
          }
          return;
        }
      }
    }
    if (fromMobile) {
      return true;
    }
    dispatch(nextStep(null, null, setHeaderMenuLeft));
  };
}

export function redirectToProfile() {
  return async (dispatch, getState) => {
    const isMobile = makeSelectIsMobile()(getState());
    dispatch(setEmailToVerify(null));
    dispatch(setProfileOption(EDIT_PROFILE_PATHNAME));
    browserHistory.push(isMobile ? EDIT_PROFILE_PATHNAME : PROFILE_PATHNAME);
  };
}
