import {
  getLoyaltyAttachDataFromSOLData as getLoyaltyAttachDataFromSOLDataGlobal,
  createUserProfileFromAppData as createUserProfileFromAppDataGlobal,
  createUserProfileFromSOLData as createUserProfileFromSOLDataGlobal,
  cleanMigrationFormData as cleanMigrationFormDataGlobal,
  createUserProfileFromLoyaltyInitialFormData as createUserProfileFromLoyaltyInitialFormDataGlobal,
  createUserProfileFormData as createUserProfileFormDataGlobal,
  getCardIdRegex as getCardIdRegexGlobal,
} from './global-utils';
import {
  getLoyaltyAttachDataFromSOLData as getLoyaltyAttachDataFromSOLDataHU,
  createUserProfileFromAppData as createUserProfileFromAppDataHU,
  createUserProfileFromSOLData as createUserProfileFromSOLDataHU,
  cleanMigrationFormData as cleanMigrationFormDataHU,
  createUserProfileFromLoyaltyInitialFormData as createUserProfileFromLoyaltyInitialFormDataHU,
  getCardIdRegex as getCardIdRegexHU,
} from './market/hu';
import {
  getLoyaltyAttachDataFromSOLData as getLoyaltyAttachDataFromSOLDataBG,
  createUserProfileFromAppData as createUserProfileFromAppDataBG,
  createUserProfileFromSOLData as createUserProfileFromSOLDataBG,
  cleanMigrationFormData as cleanMigrationFormDataBG,
  createUserProfileFromLoyaltyInitialFormData as createUserProfileFromLoyaltyInitialFormDataBG,
  getCardIdRegex as getCardIdRegexBG,
} from './market/bg';
import {
  getLoyaltyAttachDataFromSOLData as getLoyaltyAttachDataFromSOLDataPL,
  createUserProfileFromAppData as createUserProfileFromAppDataPL,
  createUserProfileFromSOLData as createUserProfileFromSOLDataPL,
  cleanMigrationFormData as cleanMigrationFormDataPL,
  createUserProfileFromLoyaltyInitialFormData as createUserProfileFromLoyaltyInitialFormDataPL,
  getCardIdRegex as getCardIdRegexPL,
} from './market/pl';
import {
  getLoyaltyAttachDataFromSOLData as getLoyaltyAttachDataFromSOLDataCZ,
  createUserProfileFromAppData as createUserProfileFromAppDataCZ,
  createUserProfileFromSOLData as createUserProfileFromSOLDataCZ,
  cleanMigrationFormData as cleanMigrationFormDataCZ,
  createUserProfileFromLoyaltyInitialFormData as createUserProfileFromLoyaltyInitialFormDataCZ,
  getCardIdRegex as getCardIdRegexCZ,
} from './market/cz';
import {
  getLoyaltyAttachDataFromSOLData as getLoyaltyAttachDataFromSOLDataSK,
  createUserProfileFromAppData as createUserProfileFromAppDataSK,
  createUserProfileFromSOLData as createUserProfileFromSOLDataSK,
  cleanMigrationFormData as cleanMigrationFormDataSK,
  createUserProfileFromLoyaltyInitialFormData as createUserProfileFromLoyaltyInitialFormDataSK,
  getCardIdRegex as getCardIdRegexSK,
} from './market/sk';
import {
  getLoyaltyAttachDataFromSOLData as getLoyaltyAttachDataFromSOLDataDE,
  createUserProfileFromAppData as createUserProfileFromAppDataDE,
  createUserProfileFromSOLData as createUserProfileFromSOLDataDE,
  cleanMigrationFormData as cleanMigrationFormDataDE,
  createUserProfileFromLoyaltyInitialFormData as createUserProfileFromLoyaltyInitialFormDataDE,
  getCardIdRegex as getCardIdRegexDE,
} from './market/de';
import {
  createUserProfileFromAppData as createUserProfileFromAppDataPH,
  createUserProfileFromLoyaltyInitialFormData as createUserProfileFromLoyaltyInitialFormDataPH,
} from './market/ph';
import {
  createUserProfileFromAppData as createUserProfileFromAppDataTH,
  createUserProfileFromLoyaltyInitialFormData as createUserProfileFromLoyaltyInitialFormDataTH,
} from './market/th';
import { createUserProfileFromLoyaltyInitialFormData as createUserProfileFromLoyaltyInitialFormDataAT } from './market/at';
import {
  HU_MARKET,
  PL_MARKET,
  BG_MARKET,
  CZ_MARKET,
  SK_MARKET,
  DE_MARKET,
  PH_MARKET,
  TH_MARKET,
  AT_MARKET,
  getMarketCountry,
} from '../markets';
import { DEBUG } from '../../shared/constants';

const getLoyaltyAttachDataFromSOLDataFunctions = {
  [HU_MARKET]: getLoyaltyAttachDataFromSOLDataHU,
  [BG_MARKET]: getLoyaltyAttachDataFromSOLDataBG,
  [PL_MARKET]: getLoyaltyAttachDataFromSOLDataPL,
  [CZ_MARKET]: getLoyaltyAttachDataFromSOLDataCZ,
  [SK_MARKET]: getLoyaltyAttachDataFromSOLDataSK,
  [DE_MARKET]: getLoyaltyAttachDataFromSOLDataDE,
};

function log(method, ...params) {
  if (DEBUG) {
    console.log(method, ...params);
  }
}

export function getLoyaltyAttachDataFromSOLData(solData, market) {
  const country = getMarketCountry(market);
  return getLoyaltyAttachDataFromSOLDataFunctions[country]
    ? getLoyaltyAttachDataFromSOLDataFunctions[country](solData)
    : getLoyaltyAttachDataFromSOLDataGlobal(solData);
}

const createUserProfileFromAppDataFunction = {
  [HU_MARKET]: createUserProfileFromAppDataHU,
  [BG_MARKET]: createUserProfileFromAppDataBG,
  [PL_MARKET]: createUserProfileFromAppDataPL,
  [CZ_MARKET]: createUserProfileFromAppDataCZ,
  [SK_MARKET]: createUserProfileFromAppDataSK,
  [DE_MARKET]: createUserProfileFromAppDataDE,
  [TH_MARKET]: createUserProfileFromAppDataTH,
  [PH_MARKET]: createUserProfileFromAppDataPH,
};

export function createUserProfileFromAppData(user, udid, guid, cvp) {
  const market = user.market?.code || user.market;
  const country = getMarketCountry(market);
  log('createUserProfileFromAppData()', user, udid, guid, cvp);
  return createUserProfileFromAppDataFunction[country]
    ? createUserProfileFromAppDataFunction[country](user, udid, guid, cvp)
    : createUserProfileFromAppDataGlobal(user, udid, guid, cvp);
}

const createUserProfileFromSOLDataFunctions = {
  [HU_MARKET]: createUserProfileFromSOLDataHU,
  [BG_MARKET]: createUserProfileFromSOLDataBG,
  [PL_MARKET]: createUserProfileFromSOLDataPL,
  [CZ_MARKET]: createUserProfileFromSOLDataCZ,
  [SK_MARKET]: createUserProfileFromSOLDataSK,
  [DE_MARKET]: createUserProfileFromSOLDataDE,
};

export function createUserProfileFromSOLData(solData, market) {
  log('createUserProfileFromSOLData()', solData, market);
  const country = getMarketCountry(market);
  return createUserProfileFromSOLDataFunctions[country]
    ? createUserProfileFromSOLDataFunctions[country](solData, market)
    : createUserProfileFromSOLDataGlobal(solData, market);
}

const cleanMigrationFormDataFunctions = {
  [HU_MARKET]: cleanMigrationFormDataHU,
  [BG_MARKET]: cleanMigrationFormDataBG,
  [PL_MARKET]: cleanMigrationFormDataPL,
  [CZ_MARKET]: cleanMigrationFormDataCZ,
  [SK_MARKET]: cleanMigrationFormDataSK,
  [DE_MARKET]: cleanMigrationFormDataDE,
};

export function cleanMigrationFormData(migrationData, market) {
  log('cleanMigrationFormData()', migrationData, market);
  const country = getMarketCountry(market);
  return cleanMigrationFormDataFunctions[country]
    ? cleanMigrationFormDataFunctions[country](migrationData, market)
    : cleanMigrationFormDataGlobal(migrationData, market);
}

const createUserProfileFromLoyaltyInitialFormDataFunctions = {
  [HU_MARKET]: createUserProfileFromLoyaltyInitialFormDataHU,
  [BG_MARKET]: createUserProfileFromLoyaltyInitialFormDataBG,
  [PL_MARKET]: createUserProfileFromLoyaltyInitialFormDataPL,
  [CZ_MARKET]: createUserProfileFromLoyaltyInitialFormDataCZ,
  [SK_MARKET]: createUserProfileFromLoyaltyInitialFormDataSK,
  [DE_MARKET]: createUserProfileFromLoyaltyInitialFormDataDE,
  [PH_MARKET]: createUserProfileFromLoyaltyInitialFormDataPH,
  [TH_MARKET]: createUserProfileFromLoyaltyInitialFormDataTH,
  [AT_MARKET]: createUserProfileFromLoyaltyInitialFormDataAT,
};

export function createUserProfileFromLoyaltyInitialFormData(
  formData,
  market,
  cvp
) {
  log('createUserProfileFromLoyaltyInitialFormData()', formData, market);
  const country = getMarketCountry(market);
  return createUserProfileFromLoyaltyInitialFormDataFunctions[country]
    ? createUserProfileFromLoyaltyInitialFormDataFunctions[country](
        formData,
        market,
        cvp
      )
    : createUserProfileFromLoyaltyInitialFormDataGlobal(formData, market, cvp);
}

const createUserProfileFormDataFunctions = {};

export function createUserProfileFormData(form, userProfile, market) {
  log('createUserProfileFormData()', form, userProfile, market);
  const country = getMarketCountry(market);
  const x =  createUserProfileFormDataFunctions[country]
    ? createUserProfileFormDataFunctions[country](form, userProfile)
    : createUserProfileFormDataGlobal(form, userProfile);
  return x; 
}

const getCardIdRegexFunctions = {
  [HU_MARKET]: getCardIdRegexHU,
  [BG_MARKET]: getCardIdRegexBG,
  [PL_MARKET]: getCardIdRegexPL,
  [CZ_MARKET]: getCardIdRegexCZ,
  [SK_MARKET]: getCardIdRegexSK,
  [DE_MARKET]: getCardIdRegexDE,
};
export function getCardIdRegex(market) {
  const country = getMarketCountry(market);
  return getCardIdRegexFunctions[country]
    ? getCardIdRegexFunctions[country]()
    : getCardIdRegexGlobal();
}
