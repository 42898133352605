import {
  SET_TWO_FA_MOBILE_FORM,
  SET_2FA_SETTINGS,
  SET_2FA_PROFILE_FLOW,
  SET_TWO_FA_PASSWORD_FORM,
  SET_TWO_FA_PASSWORD_DATA,
  SET_2FA_EDIT_PROFILE_FLOW
} from './constants';

export const initialState = {
  data: {
    mobile: '',
    password: null,
  },
  form: {},
  passwordForm: {},
  settingsFlow: false,
  fromProfile: false,
  EditProfileFlow: false,
};

const twoFaMobileChange = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case SET_TWO_FA_MOBILE_FORM:
      return {
        ...state,
        form: action.data,
        loaded: true,
      };
    case SET_TWO_FA_PASSWORD_FORM:
      return {
        ...state,
        passwordForm: action.data,
        loaded: true,
      };
    case SET_2FA_SETTINGS:
      return { ...state, settingsFlow: action.settingsBoolean };
    case SET_2FA_PROFILE_FLOW:
      return { ...state, fromProfile: action.fromProfile };
    case SET_2FA_EDIT_PROFILE_FLOW:
      return { ...state, EditProfileFlow: action.EditProfileFlow };
    case SET_TWO_FA_PASSWORD_DATA:
      return {
        ...state,
        data: { ...state.data.mobile, password: action.password },
      };
    default:
      return state;
  }
};

export default twoFaMobileChange;
