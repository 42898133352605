import lokaliseTags from '../../shared/lokaliseTags';

export const CHANGE_STEP = 'CHANGE_STEP';
export const COMPLETED = 'COMPLETED';
export const FOCUS_ATTRIBUTE = 'FOCUS_ATTRIBUTE';
export const RESET_DATA = 'RESET_DATA';
export const RESET_FORM = 'RESET_FORM';
export const RESET_MODE = 'RESET_MODE';
export const SAVE_FIELD = 'SAVE_FIELD';
export const SERVER_RESPONSE = 'SERVER_RESPONSE';
export const SET_ACTIONS = 'SET_ACTIONS';
export const SET_DATA = 'SET_DATA';
export const SET_CARD_DATA = 'SET_CARD_DATA';
export const SET_EMAIL_VERIFIED = 'SET_EMAIL_VERIFIED';
export const SET_ERROR = 'SET_ERROR';
export const REMOVE_ERROR = 'REMOVE_ERROR';
export const SET_FORM = 'SET_FORM';
export const SET_STEP = 'SET_STEP';
export const SET_FORM_PAGE = 'SET_FORM_PAGE';
export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE';
export const VALIDATE_ATTRIBUTE = 'VALIDATE_ATTRIBUTE';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const BACKEND_DATE_FORMAT = 'yyyy-mm-dd';

// FORM FIELD TYPES
export const TEXT_FORM_FIELD = 'TextFormField';
export const MASK_FORM_FIELD = 'MaskFormField';
export const MULTIPLE_SELECT_FORM_FIELD = 'MultipleSelectFormField';
export const SELECT_FORM_FIELD = 'SelectFormField';
export const PARENT_FORM_FIELD = 'ParentFormField';
export const CHECKBOX_FORM_FIELD = 'CheckboxFormField';

const DESKTOP = 'desktop';
const MOBILE = 'mobile';

export const BUTTONS_LABELS = {
  editProfile: {
    [DESKTOP]: lokaliseTags.SSO_PROFILE_SAVE_CHANGES,
    [MOBILE]: lokaliseTags.SSO_PROFILE_SAVE_CHANGES,
  },
  register: {
    [DESKTOP]: lokaliseTags.SSO_GENERAL_NEXT_LABEL,
    [MOBILE]: lokaliseTags.SSO_GENERAL_NEXT_LABEL,
  },
  forgotPassword: {
    [DESKTOP]: lokaliseTags.SSO_GENERAL_GET_STARTED_LABEL,
    [MOBILE]: lokaliseTags.SSO_GENERAL_GET_STARTED_LABEL,
  },
  verifyUser: {
    [DESKTOP]: lokaliseTags.SSO_GENERAL_CONTINUE,
    [MOBILE]: lokaliseTags.SSO_GENERAL_CONTINUE,
  },
};

export const VALIDATION_ERROR = 'VALIDATION_ERROR';
